import axios from 'axios'

export function getAllSalas () {
  return axios.get('/maestros/salas')
  .then(response => {
    return response.data
  })
}

export function updateSalas (id, sala) {
  return axios.put('/maestros/salas/' + id, sala)
  .then(response => {
      return response.data
    })
}

export function addSalas (sala) {
  return axios.post('/maestros/salas', sala)
  .then(response => {
    return response.data
  })
}

export function deleteSalas (id) {
  return axios.delete('/maestros/salas/' + id)
  .then(response => {
      return response.data
    })
}

export function getOneSalas (id) {
    return axios.get('/maestros/salas/' + id)
    .then(response => {
        return response.data
      })
}

export function getLogsSalas (id) {
  return axios.get('/maestros/salas/audit/' + id)
  .then(response => {
      return response.data
    })
}

export default {
  getAllSalas,
  updateSalas,
  addSalas,
  deleteSalas,
  getOneSalas,
  getLogsSalas,
}
